<template>
  <div class="modal-overlay" @click.self="close">
    <div class="modal-content">
      <h2>{{ title }}</h2>
      <button class="close-button" @click="close">×</button>
      <ul>
        <li v-for="item in items" :key="item.id" @click="assign(item)">
          {{ item.name }}
        </li>
      </ul>
    </div>
  </div>
</template>
  
  <script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    assign(item) {
      this.$emit('assign', item);
    },
  },
};
</script>
  
  <style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 2rem;
  width: 90%;
  max-width: 500px; 
  border-radius: 12px; 
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); 
  animation: fadeIn 0.3s ease; 
}

h2 {
  margin-top: 0;
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
}

/* Close Button Styling */
.close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 1.5rem;
  background: none;
  border: none;
  color: #999;
  cursor: pointer;
  transition: color 0.3s ease;
}

.close-button:hover {
  color: #333; 
}
ul {
  list-style: none;
  padding: 0;
  margin: 1rem 0 0 0; 
}

li {
  padding: 0.8rem 1.2rem;
  cursor: pointer;
  font-size: 1.2rem;
  border-bottom: 1px solid #eee;
  transition: background-color 0.2s ease, color 0.2s ease;
}

li:last-child {
  border-bottom: none; 
}

li:hover {
  background-color: #f9f9f9;
  color: #007bff; 
}
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
</style>
  