export default {
  computed: {
    getLabelTitleErrorPipeline() {
      let errorMessage = '';
      if (!this.$v.name.$error) {
        errorMessage = '';
      } else if (!this.$v.name.required) {
        errorMessage = this.$t('PIPELINES.REQUIRED_ERROR');
      } else if (!this.$v.name.minLength) {
        errorMessage = this.$t('PIPELINES.MINIMUM_LENGTH_ERROR');
      }else if (!this.$v.name.maxLength) {
        errorMessage = this.$t('PIPELINES.MAXIMUM_LENGTH_ERROR');
      }
      return errorMessage;
    },
    getAddMoreState(){
      let errorMessage = '';
      if (!this.$v.name.$error) {
        errorMessage = '';
      } else if (!this.$v.name.required) {
        errorMessage = this.$t('PIPELINES.REQUIRED_STATE');
      } else if (!this.$v.name.minLength) {
        errorMessage = this.$t('PIPELINES.MINIMUM_LENGTH_ERROR');
      }else if (!this.$v.name.maxLength) {
        errorMessage = this.$t('PIPELINES.MAXIMUM_LENGTH_ERROR');
      }
      return errorMessage;
    },
    getAddMoreBoard(){
      let errorMessage = '';
      if (!this.$v.name.$error) {
        errorMessage = '';
      } else if (!this.$v.name.required) {
        errorMessage = this.$t('PIPELINES.REQUIRED_BOARD');
      } else if (!this.$v.name.minLength) {
        errorMessage = this.$t('PIPELINES.MINIMUM_LENGTH_ERROR');
      }else if (!this.$v.name.maxLength) {
        errorMessage = this.$t('PIPELINES.MAXIMUM_LENGTH_ERROR');
      }
      return errorMessage;
    }
  },
};
