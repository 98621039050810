import ApiClient from './ApiClient';

class PipelinesAPI extends ApiClient {
  constructor() {
    super('pipelines', { accountScoped: true });
  }
  getstatus() {
    const updatedUrl = this.url.replace('/pipelines', '/statuses');
    return axios.get(updatedUrl);
  }

  getstate() {
    const updatedUrl = this.url.replace('/pipelines', '/states');
    return axios.get(`${updatedUrl}?only_data=${true}`);

  }
  getboards() {
    console.log("get Boards");
    const updatedUrl = this.url.replace('/pipelines', '/boards');
    return axios.get(`${updatedUrl}?only_data=${true}`);

  }

  getcontacts() {
    return axios.get(this.url);

  }
  getfiltercontact({ state_id, contact_id }) {
    const updatedUrl = this.url.replace('/pipelines', '/contacts');
    return axios.get(`${updatedUrl}/${contact_id}`, { state_id: state_id });
  }
  createstatus(statusObj) {
    const updatedUrl = this.url.replace('/pipelines', '/');
    return axios.post(`${updatedUrl}/statuses`, statusObj);
  }
  deletestatus(id) {
    const updatedUrl = this.url.replace('/pipelines', '/');
    return axios.delete(`${updatedUrl}/statuses/${id}`);
  }
  updatestatus(id) {

    const updatedUrl = this.url.replace('/pipelines', '/');
    return axios.get(`${updatedUrl}/statuses/${id}`);
  }
  updatestatusid(id, updateObj) {
    const updatedUrl = this.url.replace('/pipelines', '/');
    return axios.put(`${updatedUrl}/statuses/${id}`, updateObj);
  }
  dragstatusupdate(index, status_id) {
    const updatedUrl = this.url.replace('/pipelines', '/');
    return axios.patch(`${updatedUrl}/rearrange_status`, { index: index, status_id: status_id });
  }
  filtercontact(data) {
    console.log("Data:::", data);
    // Destructure all potential keys and values
    const { key, value, key1, value1, key2, value2, key3, value3, key4, value4, key5, value5, key6, value6 } = data;

    // Build query parameters dynamically
    let query = '';
    if (key && value) query += `&${key}=${value}`;
    if (key1 && value1) query += `&${key1}=${value1}`;
    if (key2 && value2) query += `&${key2}=${value2}`;
    if (key3 && value3) query += `&${key3}=${value3}`;
    if (key4 && value4) query += `&${key4}=${value4}`;
    if (key5 && value5) query += `&${key5}=${value5}`;
    if (key6 && value6) query += `&${key6}=${value6}`;

    return axios.get(`${this.url}?${query}`);
  }


  dragcontactupdate(new_index, contact_id, status_id) {
    console.log("Index", new_index, contact_id, status_id);
    const updatedUrl = this.url.replace('/pipelines', '/');
    return axios.patch(`${updatedUrl}rearrange_contacts`, { new_index: new_index, contact_id: contact_id, status_id: status_id });
  }
  addstate(data) {
    console.log("Index", data);
    const updatedUrl = this.url.replace('/pipelines', '/');
    return axios.post(`${updatedUrl}states`, data);
  }
  addboard(data){
    console.log("Index", data);
    const updatedUrl = this.url.replace('/pipelines', '/');
    return axios.post(`${updatedUrl}boards`, data);
  }
  lazyloading(data){
    const { key, value, key1, value1, key2, value2, key3, value3, key4, value4, key5, value5, key6, value6 } = data;

    // Build query parameters dynamically
    let query = '';
    // query+=`page=1`;
    if (key && value) query += `&${key}=${value}`;
    if (key1 && value1) query += `&${key1}=${value1}`;
    if (key2 && value2) query += `&${key2}=${value2}`;
    if (key3 && value3) query += `&${key3}=${value3}`;
    if (key4 && value4) query += `&${key4}=${value4}`;
    if (key5 && value5) query += `&${key5}=${value5}`;
    if (key6 && value6) query += `&${key6}=${value6}`;

    return axios.get(`${this.url}?${query}`);
  }
  getassignee(id) {

    const updatedUrl = this.url.replace('/pipelines', '/');
    return axios.get(`${updatedUrl}/statuses/${id}`);
  }
  selectassignee(data){
    const { key, value, key1, value1, key2, value2, key3, value3, key4, value4, key5, value5, key6, value6 } = data;

    // Build query parameters dynamically
    let query = '';
    // query+=`page=1`;
    if (key && value) query += `&${key}=${value}`;
    if (key1 && value1) query += `&${key1}=${value1}`;
    if (key2 && value2) query += `&${key2}=${value2}`;
    if (key3 && value3) query += `&${key3}=${value3}`;
    if (key4 && value4) query += `&${key4}=${value4}`;
    if (key5 && value5) query += `&${key5}=${value5}`;
    if (key6 && value6) query += `&${key6}=${value6}`;

    return axios.get(`${this.url}?${query}`);
  }
}

export default new PipelinesAPI();
