<template>
    <div>
      <woot-modal :show.sync="show" :on-close="onClose">
        <woot-modal-header :header-title="$t('PIPELINES.CREATE_BOARD')" />
        <div class="remainder-table">
          <div class="remainder-modal">
            <div class="remainder-input date-time">
              <div class="date-time-input">
                <label>
                  {{ $t('PIPELINES.EDIT_NAME') }}
                  <span class="text-red-500">*</span>
                  <woot-input
                    class="status-name"
                    :class="{ error: $v.name.$error }"
                    :placeholder="$t('PIPELINES.ENTER_NAME_BOARD')"
                    :error="getAddMoreBoard"
                    v-model.trim="name"
                    @input="$v.name.$touch"
                  />
                </label>
                <div class="flex justify-end items-center pb-6 pt-2 gap-2 w-full">
                  <woot-submit-button
                    :disabled="$v.name.$invalid || uiFlags.isCreateBoard"
                    :button-text="'Submit'"
                    @click="createBoard"
                  />
                  <woot-button class="button clear" @click.prevent="onClose">
                    {{ $t('LABEL_MGMT.FORM.CANCEL') }}
                  </woot-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </woot-modal>
    </div>
  </template>
  
  <script>
  import alertMixin from 'shared/mixins/alertMixin';
  import { mapGetters } from 'vuex';
  import validations from './helpers/validations';
  import pipelineMixin from './helpers/pipelineMixin.js';
  import { getRandomColor } from 'dashboard/helper/labelColor';
  export default {
    props: {
      show: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        name: '',
      };
    },
    validations,
    mounted() {
      this.color = getRandomColor();
    },
    mixins: [alertMixin, pipelineMixin],
    computed: {
      ...mapGetters({
        uiFlags: 'pipelines/getUIFlags',
      }),
    },
    methods: {
      onClose() {
        this.$emit('close');
      },
      async createBoard() {
        const statusObj = {
          name: this.name,
        };
        console.log('Data', statusObj);
        const response = await this.$store.dispatch(
          'pipelines/AddBoard',
          statusObj
        );
        this.onClose();
        this.showAlert(response.data.message, { success: response.data.success });
        this.resetdata();
        this.getBoards();
      },
      getBoards() {
        this.$store.dispatch('pipelines/getBoards');
      },
      resetdata(){
        this.name='',
        this.description=''
      }
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .remainder-table {
    margin: 10px 35px;
  }
  
  .remainder-modal {
    display: flex;
  }
  
  .remainder-input {
    width: 100%;
  }
  .remainder-text {
    width: 20%;
    font-size: 14px;
    font-weight: 500;
  }
  .remainder-text-select {
    padding: 5% 0px;
  }
  .input-textarea textarea {
    resize: none;
  }
  .date-time {
    display: flex;
    justify-content: space-between;
  }
  .date,
  .time {
    margin: 0px 2%;
  }
  .remainder-time {
    display: flex;
    justify-content: inherit;
    width: 35%;
  }
  .date-time-input {
    width: 100%;
  }
  .footer {
    @apply mt-6 flex justify-end;
  }
  .remainder-time select {
    width: 66%;
  }
  .remainder-footer {
    flex-direction: inherit;
  }
  .labels-dropdown {
    display: flex;
    width: 45%;
    justify-content: space-evenly;
  }
  .automation-dropdown {
    display: flex;
    width: 45%;
    justify-content: space-evenly;
  }
  .label-automation {
    padding-top: 10px;
    justify-content: space-between;
  }
  .labels-text {
    width: 30%;
  }
  .label-input {
    width: 50%;
  }
  .automation-text {
    width: 55%;
  }
  .automation-input {
    width: 50%;
  }
  .overlay {
    position: fixed;
    margin: auto;
    left: 50vw;
    width: 250px;
  }
  .close-button {
    position: absolute;
    top: 5px;
    right: 10px;
    cursor: pointer;
    font-size: 20px;
  }
  .date-time-picker-modal {
    width: 90%;
    padding-top: 2%;
  }
  </style>
      