import { required, minLength,maxLength } from 'vuelidate/lib/validators';
// export const  validLabelCharacters= (str = '') => !!str && !str.includes(' ');
export default {
  name: {
    required,
    minLength: minLength(3),
    maxLength: maxLength(20)
    // validLabelCharacters
  },
  description: {
    required,
  },
  // showOnSidebar: {},
};
