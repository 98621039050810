<template>
  <woot-modal :show.sync="show" :on-close="onClose">
    <woot-modal-header :header-title="$t('PIPELINES.SELECT_DATE')" />
    <div class="remainder-table">
      <div class="remainder-modal">
        <div class="remainder-input date-time">
          <div class="date-time-input">
            <label>
              {{ $t('PIPELINES.CHOOSE_DATE_FROM') }}
              <span class="text-red-500">*</span>
              <input
                type="date"
                v-model="scheduledAtFrom"
                :max="scheduledAtTo"
                @change="onDateChangeFrom"
              />
            </label>
            <label>
              {{ $t('PIPELINES.CHOOSE_DATE_END') }}
              <span class="text-red-500">*</span>
              <input
                type="date"
                v-model="scheduledAtTo"
                :min="scheduledAtFrom"
                @change="onDateChangeTo"
              />
            </label>
          </div>
        </div>
      </div>
      <!-- <woot-submit-button
        :button-text="'Submit'"
        :disabled="uiFlags.isFetchingContacts||isSubmitDisabled"
        @click="sendDate"
      /> -->
      <div class="flex justify-end items-center pb-6 pt-2 gap-2 w-full">
        <woot-submit-button
          :disabled="uiFlags.isFetchingContacts||isSubmitDisabled"
          :button-text="'Submit'"
          @click="sendDate"
        />
        <woot-button class="button clear" @click.prevent="onClose">
          {{ $t('LABEL_MGMT.FORM.CANCEL') }}
        </woot-button>
      </div>
    </div>
  </woot-modal>
</template>
 <script>
import WootDateTimePicker from 'dashboard/components/ui/DateTimePicker.vue';
import { mapGetters } from 'vuex';
export default {
  components: {
    WootDateTimePicker,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      scheduledAtFrom: null,
      scheduledAtTo: null,
    };
  },

  components: {
    WootDateTimePicker,
  },
  computed: {
    ...mapGetters({
      uiFlags: 'pipelines/getUIFlags',
    }),
    isSubmitDisabled() {
      // Disable the button if either date is null
      return !this.scheduledAtFrom || !this.scheduledAtTo;
    },
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    onDateChangeFrom(value) {
      console.log('My Date:', value);
      this.scheduledAtFrom = value;
      this.$emit('update-date-from', this.scheduledAtFrom);
      // this.$store.dispatch('pipelines/create', { value });
    },
    onDateChangeTo(value) {
      console.log('My Date:', value);
      this.scheduledAtTo = value;
      this.$emit('update-date-to', this.scheduledAtTo);
      // this.$store.dispatch('pipelines/create', { value });
    },
    sendDate() {
      this.$emit('update-date', {
        scheduledAtFrom: this.scheduledAtFrom,
        scheduledAtTo: this.scheduledAtTo,
      });
      this.onClose();
      // const data={
      //   key: 'start_date',
      //   value: this.scheduledAtFrom,
      //   key1: 'end_date',
      //   value1: this.scheduledAtTo
      //   }
      // console.log("Data",data);
      // this.$store.dispatch('pipelines/FilterContact',data);
    },
    onDateChangeFrom(event) {
      if (
        this.scheduledAtTo &&
        new Date(this.scheduledAtFrom) > new Date(this.scheduledAtTo)
      ) {
        this.scheduledAtTo = ''; // Reset scheduledAtTo if the selected start date is after the end date
      }
    },
    onDateChangeTo(event) {
      if (
        this.scheduledAtFrom &&
        new Date(this.scheduledAtTo) < new Date(this.scheduledAtFrom)
      ) {
        this.scheduledAtFrom = ''; // Reset scheduledAtFrom if the selected end date is before the start date
      }
    },
  },
};
</script>
  <style lang="scss" scoped>
.remainder-table {
  margin: 10px 35px;
}

.remainder-modal {
  display: flex;
}

.remainder-input {
  width: 100%;
}
.remainder-text {
  width: 20%;
  font-size: 14px;
  font-weight: 500;
}
.remainder-text-select {
  padding: 5% 0px;
}
.input-textarea textarea {
  resize: none;
}
.date-time {
  display: flex;
  justify-content: space-between;
}
.date,
.time {
  margin: 0px 2%;
}
.remainder-time {
  display: flex;
  justify-content: inherit;
  width: 35%;
}
.date-time-input {
  width: 100%;
}
.footer {
  @apply mt-6 flex justify-end;
}
.remainder-time select {
  width: 66%;
}
.remainder-footer {
  flex-direction: inherit;
}
.labels-dropdown {
  display: flex;
  width: 45%;
  justify-content: space-evenly;
}
.automation-dropdown {
  display: flex;
  width: 45%;
  justify-content: space-evenly;
}
.label-automation {
  padding-top: 10px;
  justify-content: space-between;
}
.labels-text {
  width: 30%;
}
.label-input {
  width: 50%;
}
.automation-text {
  width: 55%;
}
.automation-input {
  width: 50%;
}
.overlay {
  position: fixed;
  margin: auto;
  left: 50vw;
  width: 250px;
}
.success-popup {
  background-color: #44ce4b;
  color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  position: relative;
}
.close-button {
  position: absolute;
  top: 5px;
  right: 10px;
  cursor: pointer;
  font-size: 20px;
}
.date-time-picker-modal {
  width: 90%;
  padding-top: 2%;
}
</style>
  