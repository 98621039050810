import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import types from '../mutation-types';
import PipelinesAPI from '../../api/pipelines';
import AnalyticsHelper from '../../helper/AnalyticsHelper';
import { CAMPAIGNS_EVENTS } from '../../helper/AnalyticsHelper/events';

export const state = {
  records: [],
  status_data: [],
  boards: [],
  uiFlags: {
    isFetching: false,
    isCreating: false,
    isCreateState: false,
    isCreateBoard: false,
    isFetchingContacts: false,
    islazyloading: false
  },
  pipelines_contact: {}
};

export const getters = {
  getUIFlags(_state) {
    return _state.uiFlags;
  },
  //   getCampaigns: _state => campaignType => {
  //     return _state.records.filter(
  //       record => record.campaign_type === campaignType
  //     );
  //   },
  getAllState: _state => {
    return _state.records;
  },
  getAllBoards: _state => {
    return _state.boards;
  },
  getAllStatus: _state => {
    return _state.status_data;
  },
  getAllContacts: _state => {
    return _state.piplines_contact;
  }
};

export const actions = {
  getStatus: async ({ commit }) => {
    commit(types.SET_CAMPAIGN_UI_FLAG, { isFetching: true });
    try {
      const response = await PipelinesAPI.getstatus();
      commit(types.SET_CAMPAIGNS, response.data.data);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_CAMPAIGN_UI_FLAG, { isFetching: false });
    }
  },
  createStatus: async ({ commit }, statusObj) => {
    commit(types.SET_CAMPAIGN_UI_FLAG, { isCreating: true });
    try {
      const response = await PipelinesAPI.createstatus(statusObj);
      // console.log("Response",response);
      commit(types.ADD_CAMPAIGN, response.data);
      return response;
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_CAMPAIGN_UI_FLAG, { isCreating: false });
    }
  },
  UpdateStatus: async ({ commit }, id) => {
    commit(types.SET_CAMPAIGN_UI_FLAG, { isUpdating: true });
    try {
      console.log("My Id is", id);
      const response = await PipelinesAPI.updatestatus(id);
      // console.log("My Response", response);
      commit(types.EDIT_CAMPAIGN, response.data);
      return response;
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_CAMPAIGN_UI_FLAG, { isUpdating: false });
    }
  },
  deleteStatus: async ({ commit }, id) => {
    commit(types.SET_CAMPAIGN_UI_FLAG, { isDeleting: true });
    try {
      const response = await PipelinesAPI.deletestatus(id);

      commit(types.DELETE_CAMPAIGN, id);
      return response;
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_CAMPAIGN_UI_FLAG, { isDeleting: false });

    }
  },
  getState: async ({ commit }) => {
    try {
      const response = await PipelinesAPI.getstate();
      // console.log("Response",response);
      commit(types.SET_STATUS, response.data.states_data);
    } catch (err) {
      console.error("Error while Fetching the data", err);
    }
  },
  getBoards: async ({ commit }) => {
    try {  
      const response = await PipelinesAPI.getboards();
      commit(types.SET_BOARDS, response.data.boards_data);
    } catch (err) {
      console.error("Error while Fetching the data", err);
    }
  },
  getContact: async ({ commit }) => {
    try {
      commit(types.SET_CAMPAIGN_UI_FLAG, { isFetching: true });
      const response = await PipelinesAPI.getcontacts();
      // console.log("Response",response);
      commit(types.SET_PIPELINE_CONTACTS, response.data);
      commit(types.SET_PIPELINE_CONTACTS_DATA, response.data.contacts_data);
    } catch (err) {
      console.error("Error while Fetching the data", err);
    }
    finally {
      commit(types.SET_CAMPAIGN_UI_FLAG, { isFetching: false });
    }
  },
  updateStatusId: async ({ commit }, { id, updateObj }) => {
    commit(types.SET_CAMPAIGN_UI_FLAG, { isUpdating: true });
    try {
      console.log("My Id is", id, updateObj);
      const response = await PipelinesAPI.updatestatusid(id, updateObj);
      // console.log("My Response", response);
      commit(types.EDIT_CAMPAIGN, response.data);
      return response;
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_CAMPAIGN_UI_FLAG, { isUpdating: false });
    }
  },
  DragstatusUpdate: async ({ commit }, { oldindex, index, status_id }) => {
    // commit(types.SET_CAMPAIGN_UI_FLAG, { isFetching: true });
    try {
      console.log("My Id is", index, status_id, oldindex);
      const newIndex = index;
      const stateobj = {
        oldIndex: oldindex,
        newIndex: newIndex
      }
      commit(types.UPDATE_DRAG_STATE, stateobj);
      const response = await PipelinesAPI.dragstatusupdate(index, status_id);
      // console.log("My Response", response);
      return response;
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_CAMPAIGN_UI_FLAG, { isFetching: false });
    }
  },
  FilterContact: async ({ commit }, data) => {
    commit(types.SET_CAMPAIGN_UI_FLAG, { isFetchingContacts: true });
    try {
      console.log("My Data:::::", data);
      const response = await PipelinesAPI.filtercontact(data);
      console.log("Response", response);
      commit(types.SET_PIPELINE_CONTACTS_DATA, response.data.contacts_data);
    } catch (err) {
      throw new Error(error);
    } finally {
      commit(types.SET_CAMPAIGN_UI_FLAG, { isFetchingContacts: false });
    }
  },
  DragcontactUpdate: async ({ commit }, { new_index, contact_id, status_id }) => {
    // commit(types.SET_CAMPAIGN_UI_FLAG, { isFetching: true });
    try {
      console.log("My Id is", new_index, contact_id, status_id);
      const response = await PipelinesAPI.dragcontactupdate(new_index, contact_id, status_id);
      console.log("My Response", response);
      return response;
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_CAMPAIGN_UI_FLAG, { isFetching: false });
    }
  },
  AddState: async ({ commit }, data) => {
    console.log("MY data", data);
    commit(types.SET_CAMPAIGN_UI_FLAG, { isCreateState: true });
    try {
      const response = await PipelinesAPI.addstate(data);
      console.log("Response::::", response);
      return response;
    } catch (err) {
      throw new Error(error);
    } finally {
      commit(types.SET_CAMPAIGN_UI_FLAG, { isCreateState: false });
    }
  },
  AddBoard: async ({ commit }, data) => {
    console.log("MY data", data);
    commit(types.SET_CAMPAIGN_UI_FLAG, { isCreateBoard: true });
    try {
      const response = await PipelinesAPI.addboard(data);
      console.log("Response::::", response);
      return response;
    } catch (err) {
      throw new Error(error);
    } finally {
      commit(types.SET_CAMPAIGN_UI_FLAG, { isCreateBoard: false });
    }
  },
  Lazyloading: async ({ commit }, data) => {
    commit(types.SET_CAMPAIGN_UI_FLAG, { islazyloading: true });
    try {
      console.log("My Data:::::", data);
      const response = await PipelinesAPI.lazyloading(data);
      console.log("Response", response);
      
      commit(types.SET_PIPELINE_CONTACTS_LAZY_LOADING_DATA, {
        contacts: response.data.contacts_data,
        page: response.data.page
      });
      return response;
    } catch (err) {
      throw new Error(error);
    } finally {
      commit(types.SET_CAMPAIGN_UI_FLAG, { islazyloading: false });
    }
  },
  getAssignee: async ({ commit }, data) => {
    // commit(types.SET_CAMPAIGN_UI_FLAG, { islazyloading: true });
    try {
      console.log("My Data:::::", data);
      const response = await PipelinesAPI.getassignee(data);
      console.log("Response", response);
      
      // commit(types.SET_PIPELINE_CONTACTS_LAZY_LOADING_DATA, {
      //   contacts: response.data.contacts_data,
      //   page: response.data.page
      // });
      return response;
    } catch (err) {
      throw new Error(error);
    } finally {
      commit(types.SET_CAMPAIGN_UI_FLAG, { islazyloading: false });
    }
  },
  selectAssignee: async ({ commit }, data) => {
    // commit(types.SET_CAMPAIGN_UI_FLAG, { islazyloading: true });
    try {
      console.log("My Data:::::", data);
      const response = await PipelinesAPI.selectassignee(data);
      console.log("Response", response);
      
      // commit(types.SET_PIPELINE_CONTACTS_LAZY_LOADING_DATA, {
      //   contacts: response.data.contacts_data,
      //   page: response.data.page
      // });
      return response;
    } catch (err) {
      throw new Error(error);
    } finally {
      commit(types.SET_CAMPAIGN_UI_FLAG, { islazyloading: false });
    }
  },

};

export const mutations = {
  [types.SET_CAMPAIGN_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },

  [types.ADD_CAMPAIGN]: MutationHelpers.create,
  [types.SET_CAMPAIGNS]: MutationHelpers.set,
  [types.SET_STATUS]: MutationHelpers.setStatus,
  [types.SET_BOARDS]: MutationHelpers.setBoards,
  [types.SET_PIPELINE_CONTACTS]: MutationHelpers.setContact,
  [types.SET_PIPELINE_CONTACTS_DATA]: MutationHelpers.setContacttoItems,
  [types.EDIT_CAMPAIGN]: MutationHelpers.update,
  [types.DELETE_CAMPAIGN]: MutationHelpers.destroy,
  [types.UPDATE_DRAG_STATE]: MutationHelpers.updatedrag,
  [types.SET_PIPELINE_CONTACTS_LAZY_LOADING_DATA]: MutationHelpers.lazyloadingcontacts
};

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};
