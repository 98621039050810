<template>
  <woot-modal :show.sync="show" :on-close="onClose">
    <woot-modal-header :header-title="$t('PIPELINES.EDIT_STATUS')" />
    <div class="remainder-table">
      <div class="remainder-modal">
        <div class="remainder-input date-time">
          <div class="date-time-input">
            <label>
              {{ $t('PIPELINES.EDIT_NAME') }}
              <span class="text-red-500">*</span>
              <woot-input
                class="status-name"
                :class="{ error: $v.name.$error }"
                :placeholder="'Your Name'"
                v-model.trim="name"
                :error="getLabelTitleErrorPipeline"
                @input="$v.name.$touch"
              />
            </label>
            <label>
              {{ $t('PIPELINES.EDIT_DESCRIPTION') }}
              <span class="text-red-500">*</span>
              <woot-input
                class="status-description"
                :class="{ error: $v.description.$error }"
                :placeholder="'Your Description'"
                v-model.trim="description"
                @input="$v.description.$touch"
              />
            </label>
            <label>
              {{ $t('PIPELINES.COLOR') }}
              <span class="text-red-500">*</span>
              <!-- <input
                class="status-name"
                placeholder="'Your Color'"
                type="color"
                v-model="color"
                id="color"
              /> -->
              <woot-color-picker v-model="color" />
            </label>
            <!-- <label>
              <label for="draggable">Draggable:</label>
              <input type="checkbox" v-model="draggable" id="draggable" />
            </label> -->
            <!-- <div>
              <woot-submit-button :button-text="'Edit'" :disabled="uiFlags.isUpdating" @click="editStatus" />
            </div> -->
            <div class="flex justify-end items-center pb-6 pt-2 gap-2 w-full">
              <woot-submit-button :button-text="'Update'" :disabled="$v.name.$invalid || uiFlags.isUpdating" @click="editStatus" />
              <woot-button class="button clear" @click.prevent="onClose">
                {{ $t('LABEL_MGMT.FORM.CANCEL') }}
              </woot-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </woot-modal>
</template>

<script>
import alertMixin from 'shared/mixins/alertMixin';
import { mapGetters } from 'vuex';
import validations from './helpers/validations';
import pipelineMixin from './helpers/pipelineMixin.js'
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    getModaldata: {
      type: Object,
      default: () => ({}),
      required: false,
    },
  },
  mixins: [alertMixin,pipelineMixin],
  data() {
    return {
      name: this.getModaldata.name || '',
      description: this.getModaldata.description || '',
      color: this.getModaldata.color || '',
      id: this.getModaldata.id || null,
      draggable: this.getModaldata.dragable || false,
    };
  },
  validations,
  computed: {
    ...mapGetters({
      uiFlags: 'pipelines/getUIFlags',
    }),
  },
  mounted() {
    console.log('Get Modal Data', this.getModaldata);
  },
  watch: {
    getModaldata(newVal) {
      // Log whenever the prop changes
      console.log('getModaldata updated:', newVal, newVal.color);
      this.id = newVal.id;
      this.name = newVal.name;
      this.description = newVal.description;
      this.color = newVal.color;
      this.draggable = newVal.dragable;
    },
  },
  methods: {
    async editStatus() {
      // try {
      const updateObj = {
        name: this.name,
        description: this.description,
        color: this.color,
        draggable: this.draggable,
      };
      const id = this.id;
      console.log('My Id is', id, updateObj);
      //   let response = await axios.put(
      //     `api/v1/accounts/${this.$route.params.accountId}/statuses/${id}`,
      //     dataToSend
      //   );
      //   console.log('Response', response);
      // } catch (error) {
      //   console.error('Error sending data to server:', error);
      // } finally {
      //   this.onClose();
      //   this.showAlert(response.data.message);
      // }
      const response = await this.$store.dispatch('pipelines/updateStatusId', {
        id,
        updateObj,
      });
      console.log('Response', response);
      this.onClose();
      this.getStatus();
      this.showAlert(response.data.message, { success: response.data.success });
    },
    async getStatus() {
      this.$store.dispatch('pipelines/getStatus');
      const data = {
          key: 'state_id',
          value: '', // The state filter
          key1: 'name',
          value1: '', // The name filter
          key2: 'start_date',
          value2: '', // The start date filter
          key3: 'end_date',
          value3: '', // The end date filter
        };
        this.$store.dispatch('pipelines/FilterContact', data);
    },
    onClose() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.remainder-table {
  margin: 10px 35px;
}

.remainder-modal {
  display: flex;
}

.remainder-input {
  width: 100%;
}
.remainder-text {
  width: 20%;
  font-size: 14px;
  font-weight: 500;
}
.remainder-text-select {
  padding: 5% 0px;
}
.input-textarea textarea {
  resize: none;
}
.date-time {
  display: flex;
  justify-content: space-between;
}
.date,
.time {
  margin: 0px 2%;
}
.remainder-time {
  display: flex;
  justify-content: inherit;
  width: 35%;
}
.date-time-input {
  width: 100%;
}
.footer {
  @apply mt-6 flex justify-end;
}
.remainder-time select {
  width: 66%;
}
.remainder-footer {
  flex-direction: inherit;
}
.labels-dropdown {
  display: flex;
  width: 45%;
  justify-content: space-evenly;
}
.automation-dropdown {
  display: flex;
  width: 45%;
  justify-content: space-evenly;
}
.label-automation {
  padding-top: 10px;
  justify-content: space-between;
}
.labels-text {
  width: 30%;
}
.label-input {
  width: 50%;
}
.automation-text {
  width: 55%;
}
.automation-input {
  width: 50%;
}
.overlay {
  position: fixed;
  margin: auto;
  left: 50vw;
  width: 250px;
}
.success-popup {
  background-color: #44ce4b;
  color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  position: relative;
}
.close-button {
  position: absolute;
  top: 5px;
  right: 10px;
  cursor: pointer;
  font-size: 20px;
}
.date-time-picker-modal {
  width: 90%;
  padding-top: 2%;
}
</style>
  