<template>
  <div
    class="h-full w-16 bg-white dark:bg-slate-900 border-r border-slate-50 dark:border-slate-800/50 rtl:border-l rtl:border-r-0 flex justify-between flex-col sidebar"
  >
    <div class="flex flex-col items-center">
      <logo
        :source="logoSource"
        :name="installationName"
        :account-id="accountId"
        class="m-4 mb-10"
      />
      

      <primary-nav-item
        v-for="menuItem in menuItems"
        :key="menuItem.toState"
        :icon="menuItem.icon"
        :name="menuItem.label"
        :to="menuItem.toState"
        :is-child-menu-active="menuItem.key === activeMenuItem"
      />
      <!-- <woot-button icon="call-start" @click="emitCancelButtonEvent" /> -->
      <dial-pad
        :show="showDraggablemodal"
        :result="result"
        @cancel="emitCancelButtonEvent"
      />
      <div>
        <draggable-file
          class="dialpadModal"
          :show="showDragmodal"
          :formatted="formattedTime"
          :hangup="hangupIncomingCall"
          :selectedContact="selectedContact"
          @incomingCallAcceptButton="acceptIncomingCall"
          @rejectCall="rejectIncomingCall"
        />
      </div>
    </div>
    <div class="flex flex-col items-center justify-end pb-6">
      <primary-nav-item
        v-if="!isACustomBrandedInstance"
        icon="book-open-globe"
        name="DOCS"
        :open-in-new-page="true"
        :to="helpDocsURL"
      />
      <notification-bell @open-notification-panel="openNotificationPanel" />
      <agent-details @toggle-menu="toggleOptions" />
      <options-menu
        :show="showOptionsMenu"
        @toggle-accounts="toggleAccountModal"
        @show-support-chat-window="toggleSupportChatWindow"
        @key-shortcut-modal="$emit('key-shortcut-modal')"
        @close="toggleOptions"
      />
    </div>
  </div>
</template>
<script>
import Logo from './Logo';
import PrimaryNavItem from './PrimaryNavItem';
import OptionsMenu from './OptionsMenu';
import AgentDetails from './AgentDetails';
import NotificationBell from './NotificationBell';
import wootConstants from 'dashboard/constants/globals';
import { frontendURL } from 'dashboard/helper/URLHelper';
import { ACCOUNT_EVENTS } from '../../../helper/AnalyticsHelper/events';
import DialPad from './DialPad.vue';
import DialerPad from './ContactDailerpad';
import { mapGetters } from 'vuex';
import DraggableFile from '../../widgets/WootWriter/Draggable.vue';
let token;
let device;
let call;

export default {
  components: {
    Logo,
    PrimaryNavItem,
    OptionsMenu,
    AgentDetails,
    NotificationBell,
    DialPad,
    DialerPad,
    DraggableFile,
  },
  props: {
    isACustomBrandedInstance: {
      type: Boolean,
      default: false,
    },
    logoSource: {
      type: String,
      default: '',
    },
    installationName: {
      type: String,
      default: '',
    },
    accountId: {
      type: Number,
      default: 0,
    },
    menuItems: {
      type: Array,
      default: () => [],
    },
    activeMenuItem: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      helpDocsURL: wootConstants.DOCS_URL,
      showOptionsMenu: false,
      showDraggablemodal: false,
      result: [],
      showCallButton: true,
      currentIcon: 'call-start',
      calltitle: 'CONVERSATION.REPLYBOX.CALL',
      showDragmodal: false,
      timerRunning: false,
      seconds: 0,
      minutes: 0,
      hours: 0,
      twilio_call: {},
      selectedContact: {},
    };
  },
  mounted() {
    this.startupClient();
  },
  computed: {
    ...mapGetters({
      baseUrl: 'getbaseUrl',
      ishold: 'contacts/getHoldState',
      holdCallUi: 'contacts/getHoldCallUidata',
      current_userId: 'getCurrentUser',
      current_user_id: 'getCurrentUserID'
    }),
    formattedTime() {
      return (
        this.pad(this.hours) +
        ':' +
        this.pad(this.minutes) +
        ':' +
        this.pad(this.seconds)
      );
    },
  },
  watch: {
    holdCallUi(newValue) {
      console.log("Data",newValue);
      if(newValue.count===0)
      {
        this.showDragmodal=false;
      }
    }
  },
  methods: {
    frontendURL,
    toggleOptions() {
      this.showOptionsMenu = !this.showOptionsMenu;
    },
    toggleAccountModal() {
      this.$emit('toggle-accounts');
    },
    toggleSupportChatWindow() {
      window.$chatwoot.toggle();
    },
    openNotificationPanel() {
      this.$track(ACCOUNT_EVENTS.OPENED_NOTIFICATIONS);
      this.$emit('open-notification-panel');
    },
    async emitCancelButtonEvent() {
      try {
        // You can add any other logic here if needed
        this.showDraggablemodal = !this.showDraggablemodal;

        // Dispatch the action and wait for the response
        this.result = await this.$store.dispatch('contacts/dialpadContacts');
      } catch (error) {
        // Handle errors if needed
        console.error(error);
      }
    },

    async handleIncomingCall(call) {
      this.twilio_call = call;
      const response = await axios.get(
        `${this.baseUrl}/twilio/fetch_contact`,
        {
          params: {
            account_id: this.accountId,
            phone_number: call.parameters.From,
          },
        }
      );
      this.resetTimer();
      this.$store.dispatch('contacts/toggleAuthStatusTrue');
      this.$store.dispatch('contacts/toggleholdCallAvailableStatusTrue');
      this.showDragmodal = true;
      this.selectedContact = {
        phone_number: response.data.data.phone_number,
        name: response.data.data.name,
      };
      let data = call.parameters;
      data.account_id = this.accountId;
      data.userId = this.current_user_id;
      await axios.post(`${this.baseUrl}/twilio/handle_incoming_call`, data);

      call.on('cancel', this.handleDisconnectedIncomingCall);
      call.on('disconnect', this.handleDisconnectedIncomingCall);
      call.on('reject', this.handleDisconnectedIncomingCall);
    },

    acceptIncomingCall() {
      this.twilio_call.accept();
      this.startTimer();
      this.$store.dispatch('contacts/toggleAuthStatusFalse');
    },

    rejectIncomingCall() {
      this.twilio_call.reject();
      this.$store.dispatch('contacts/toggleAuthStatusFalse');
    },

    hangupIncomingCall() {
      console.log('Disconnect incoming Call');
      this.twilio_call.disconnect();
      this.showDragmodal = false;
      this.stopTimer();
    },

    handleDisconnectedIncomingCall() {
      this.showDragmodal = false;
      this.stopTimer();
      if (this.ishold == true) {
        this.resetTimer();
        this.startTimer();
      } else if (this.ishold == false) {
        this.showDragmodal = false;
        this.stopTimer();
      }
    },

    addDeviceListeners(device) {
      device.on('registered', function () {
        console.log('Twilio.Device Ready to make and receive calls!');
      });
      device.on('error', function (error) {
        console.log('Twilio.Device Error: ' + error.message);
      });
      device.on('incoming', (call) => {
      console.log('Incoming call received!::::::::::::::::::::::::::');
      this.handleIncomingCall(call);
      });
    },

    async intitializeDevice() {
      console.log('Initializing device');
      device = new Twilio.Device(token, {
        logLevel: 1,
      });
      this.addDeviceListeners(device);
      device.register();
    },

    async getAudioDevices() {
      await navigator.mediaDevices.getUserMedia({ audio: true });
    },

    async startupClient() {
      try {
        this.getAudioDevices();
        console.log('Requesting Access Token...');
        const data = await axios.get(`${this.baseUrl}/twilio/token`, {
          params: {
            account_id: this.accountId,
            user_id:  this.current_user_id,
          },
        });
        token = data.data.token;
        console.log('got token' + token);
        await this.intitializeDevice();
      } catch (err) {
        console.log(err);
        console.log(
          'An error occurred. See your browser console for more information.'
        );
      }
    },
    startTimer() {
      this.timerRunning = true;
      this.timer = setInterval(this.updateTimer, 1000);
    },
    stopTimer() {
      clearInterval(this.timer);
      this.timerRunning = false;
    },
    updateTimer() {
      this.seconds++;
      if (this.seconds === 60) {
        this.seconds = 0;
        this.minutes++;
        if (this.minutes === 60) {
          this.minutes = 0;
          this.hours++;
        }
      }
    },
    pad(value) {
      return value < 10 ? '0' + value : value;
    },
    resetTimer() {
      this.stopTimer();
      this.seconds = 0;
      this.minutes = 0;
      this.hours = 0;
    },
  },
};
</script>

<style scoped>
.sidebar {
  z-index: 98; /* Increase this value to ensure it's above other content */
  background-color: #40518A;
}
.white-color{
  background-color: #fff;
}
</style>
