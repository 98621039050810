<template>
  <div>
    <div class="assignee-ui">
      <div
        v-for="assignee in assignees"
        :key="assignee.id"
        :class="['assignee-data', { active: assignee.id === activeAssigneeId }]"
        @click="setActiveAssignee(assignee.id)"
      >
        <div class="assignee-img assignee-info">
          <thumbnail
            :key="assignee.id"
            :src="assignee.src"
            :variant="'circle'"
            :username="assignee.name"
            class="user-thumbnail"
            size="30px"
          />
        </div>
        <div class="assignee-name assignee-info">{{ assignee.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import Thumbnail from '../../../../components/widgets/Thumbnail.vue';
export default {
  components: {
    Thumbnail,
  },
  props: {
    assignees: {
      type: Array,
      required: true,
    },
    currentassignee: {
      type: [Number, Object], // Accepts both Number or Object
      default: null, // Provide a default value if necessary
    },
  },
  data() {
    return {
      activeAssigneeId: null, // To track the currently active assignee
    };
  },
  mounted(){
    this.activeAssigneeId=this.currentassignee;
  },
  methods: {
    async setActiveAssignee(id) {
      this.activeAssigneeId = id; // Set the clicked assignee as active
      // const response = await this.$store.dispatch(
      //   'pipelines/selectAssignee',
      //   status_id
      // );
      // console.log('response', response);
      this.onClose(id);
    },
    onClose(id) {
      this.$emit('close', id);
    },
  },
};
</script>

<style lang="scss" scoped>
.assignee-ui {
  width: 251px;
  height: 168px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  opacity: 1;
  overflow: auto;
}
.assignee-data {
  display: flex;
  border-bottom: 2px solid lightgray;
  height: 56px;
}
.assignee-info {
  display: flex;
  align-items: center;
  padding: 0px 10px;
}
.active {
  background: #1f94ff;
  color: #fff;
}
.assignee-ui::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.assignee-ui {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  margin-top: 15px;
}
</style>