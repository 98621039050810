var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('router-link',{attrs:{"to":_vm.to,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var isActive = ref.isActive;
var navigate = ref.navigate;
return [_c('a',{staticClass:"text-slate-700 dark:text-slate-100 w-10 h-10 my-2 flex items-center justify-center rounded-lg hover:bg-slate-25 dark:hover:bg-slate-700 dark:hover:text-slate-100 hover:text-slate-600 relative",class:{
      'bg-woot-50 dark:bg-slate-800 text-woot-500 hover:bg-woot-50 background-light':
        isActive || _vm.isChildMenuActive,
    },attrs:{"href":href,"rel":_vm.openInNewPage ? 'noopener noreferrer nofollow' : undefined,"target":_vm.openInNewPage ? '_blank' : undefined},on:{"click":navigate,"mouseenter":_vm.showSecondaryMenu}},[_c('fluent-icon',{staticClass:"light-grey",class:{
        'text-woot-500 white-color': isActive || _vm.isChildMenuActive,
      },attrs:{"icon":_vm.icon,"viewBox":'-10 -10 50 50',"size":'40'}}),_vm._v(" "),_c('span',{staticClass:"sr-only"},[_vm._v(_vm._s(_vm.name))]),_vm._v(" "),(_vm.count)?_c('span',{staticClass:"text-black-900 bg-yellow-500 absolute -top-1 -right-1"},[_vm._v("\n      "+_vm._s(_vm.count)+"\n    ")]):_vm._e()],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }