<template>
  <div>
    <div v-if="showSuccessMessage" class="overlay">
      <div class="success-popup">
        <span class="close-button" @click="closeSuccessMessage">&times;</span>
        {{ successMessage }}
      </div>
    </div>
    <div v-if="visible" class="modal-overlay" @click.self="close">
      <div class="modal-content">
        <button class="close-button" @click="close">×</button>
        <ul>
          <li @click="openModal('boards')">📌 Assign Board</li>
          <li @click="openModal('states')">📍 Assign State</li>
          <li @click="openModal('statuses')">✅ Assign Status</li>
        </ul>
      </div>
    </div>
    <DynamicModal
      v-if="isModalVisible"
      :title="modalTitle"
      :items="modalItems"
      @close="closeModal"
      @assign="assign"
    />
  </div>
</template>
<script>
import DynamicModal from './DynamicModal.vue';
import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
  components: {
    DynamicModal,
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      accountId: 'getCurrentAccountId',
    }),
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    selectedContact: {
      type: Object,
      required: true,
      default: () => null
    },
  },
  data() {
    return {
      isModalVisible: false,
      modalItems: [],
      modalTitle: '',
      selectedType: '',
      successMessage: '',
      showSuccessMessage: false,
    };
  },
  methods: {
    close() {
      this.$emit('update:visible', false);
    },
    openModal(type) {
      this.selectedType = type;
      switch (type) {
        case 'boards':
          this.modalTitle = 'Assign Board';
          this.fetchBoards();
          break;
        case 'states':
          this.modalTitle = 'Assign State';
          this.fetchStates();
          break;
        case 'statuses':
          this.modalTitle = 'Assign Status';
          this.fetchStatuses();
          break;
      }
      this.isModalVisible = true;
    },
    async fetchBoards() {
      try {
        const response = await axios.get(
          `/api/v1/accounts/${this.accountId}/boards`,
          {
            headers: {
              'Content-Type': 'application/json',
              api_access_token: this.currentUser.access_token,
            },
          }
        );
        this.modalItems = response.data.boards_data;
      } catch (error) {
        this.error = 'Failed to load boards';
        console.error('Error fetching boards:', error);
      } finally {
        this.isLoading = false;
      }
    },
    async fetchStates() {
      try {
        const response = await axios.get(
          `/api/v1/accounts/${this.accountId}/states`,
          {
            headers: {
              'Content-Type': 'application/json',
              api_access_token: this.currentUser.access_token,
            },
          }
        );
        this.modalItems = response.data.states_data;
      } catch (error) {
        this.error = 'Failed to load states';
        console.error('Error fetching states:', error);
      } finally {
        this.isLoading = false;
      }
    },
    async fetchStatuses() {
      try {
        const response = await axios.get(
          `/api/v1/accounts/${this.accountId}/statuses`,
          {
            headers: {
              'Content-Type': 'application/json',
              api_access_token: this.currentUser.access_token,
            },
          }
        );
        this.modalItems = response.data.data;
      } catch (error) {
        this.error = 'Failed to load statuses';
        console.error('Error fetching statuses:', error);
      } finally {
        this.isLoading = false;
      }
    },
    closeModal() {
      this.isModalVisible = false;
    },
    assign(item) {
      switch (this.selectedType) {
        case 'boards':
          this.assignBoard(item);
          break;
        case 'states':
          this.assignState(item);
          break;
        case 'statuses':
          this.assignStatus(item);
          break;
      }
      this.closeModal();
    },
    async assignBoard(item) {
      try {
        const response = await axios.put(
          `/api/v1/accounts/${this.accountId}/contacts/${this.selectedContact.id}`,
          {
            board_id: item.id,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              api_access_token: this.currentUser.access_token,
            },
          }
        );
        if (response.status === 200) {
          this.successMessage = 'Board assigned successfully';
          this.showSuccessMessage = true;
          this.$emit('update:visible', false);
          setTimeout(() => {
            this.closeSuccessMessage();
          }, 2000);
        } else {
          this.errorMessage = 'Unexpected response from server.';
        }
      } catch (error) {
        console.error(
          `Error assigning board ${item.id} to contact ${this.selectedContact.id}:`,
          error
        );
        this.errorMessage = 'Failed to assign board. Please try again.';
      }
    },
    async assignState(item) {
      try {
        const response = await axios.put(
          `/api/v1/accounts/${this.accountId}/contacts/${this.selectedContact.id}`,
          {
            state_id: item.id,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              api_access_token: this.currentUser.access_token,
            },
          }
        );
        if (response.status === 200) {
          this.successMessage = 'State assigned successfully';
          this.showSuccessMessage = true;
          this.$emit('update:visible', false);
          setTimeout(() => {
            this.closeSuccessMessage();
          }, 2000);
        } else {
          this.errorMessage = 'Unexpected response from server.';
        }
      } catch (error) {
        console.error(
          `Error assigning state ${item.id} to contact ${this.selectedContact.id}:`,
          error
        );
      }
    },

    async assignStatus(item) {
      try {
        const response = await axios.put(
          `/api/v1/accounts/${this.accountId}/contacts/${this.selectedContact.id}`,
          {
            status_id: item.id,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              api_access_token: this.currentUser.access_token,
            },
          }
        );
        if (response.status === 200) {
          this.successMessage = 'Status assigned successfully';
          this.showSuccessMessage = true;
          this.$emit('update:visible', false);
          setTimeout(() => {
            this.closeSuccessMessage();
          }, 2000);
        } else {
          this.errorMessage = 'Unexpected response from server.';
        }
      } catch (error) {
        console.error(
          `Error assigning status ${item.id} to contact ${this.selectedContact.id}:`,
          error
        );
      }
    },
    closeSuccessMessage() {
      this.showSuccessMessage = false;
      this.successMessage = '';
    },
  },
};
</script>
  <style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.modal-content {
  background: white;
  padding: 2rem;
  width: 90%;
  max-width: 500px;
  border-radius: 15px;
  position: relative;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  animation: fadeIn 0.3s ease;
}

.close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 1.5rem;
  background: none;
  border: none;
  color: #999;
  cursor: pointer;
  transition: color 0.3s ease;
}

.close-button:hover {
  color: #333;
}

/* Modal List Styling */
.modal-content ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.overlay {
  position: fixed;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  width: 250px;
  z-index: 1000; /* Ensure it's on top */
}

.success-popup {
  background-color: #44ce4b;
  color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  position: relative;
}

.close-button {
  position: absolute;
  top: 5px;
  right: 10px;
  cursor: pointer;
  font-size: 20px;
}

.modal-content ul li {
  padding: 0.8rem 1.5rem;
  font-size: 1.2rem;
  cursor: pointer;
  border-bottom: 1px solid #eee;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.modal-content ul li:last-child {
  border-bottom: none;
}

.modal-content ul li:hover {
  background-color: #f1f1f1;
  color: #007bff;
}

/* Fade-in animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
</style>
  